import React from 'react'

import { AssetsTable } from 'components/assets'
import useFetchAssets from 'js/assets/hooks/useFetchAssets'

import 'components/pages/assets/scss/assets-section.scss'

function AssetsSection({ data }) {
  const { combinedAssets, count } = useFetchAssets()

  return (
    <section className="x__assets-page__assets">
      <AssetsTable data={data} assets={combinedAssets} count={count.all} />
    </section>
  )
}

export default AssetsSection
