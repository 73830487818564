import React, { useRef } from 'react'

import { useRellax } from 'js/utils/hooks'

import 'components/pages/assets/scss/header-section.scss'

function HeaderSection({ data }) {
  const backgroundRef = useRef(null)

  useRellax({ ref: backgroundRef })

  return (
    <header className="x__assets-page__header">
      <div
        ref={backgroundRef}
        className="x__assets-page__header__background-container"
        data-rellax-speed={-4}
      />

      <div className="x__assets-page__header__content">
        <h1
          className="x__assets-page__header__heading"
          dangerouslySetInnerHTML={{ __html: data.h1 }}
        />
        <p
          className="x__assets-page__header__subheading"
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      </div>
    </header>
  )
}

export default HeaderSection
